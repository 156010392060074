@import "./variables";

.app-table__wrapper {
  overflow: auto;
}

.app-static-content table,
.app-table {
  border-collapse: collapse;
  margin-top: 30px;
  width: 100%;
}

.app-static-content table td,
.app-table td {
  padding: 10px;
  border-bottom: 1px solid $lightGray3;
}

.app-static-content table tbody tr:nth-child(even) td,
.app-table tbody:not(.app-table--macro__overview__table__body) tr:nth-child(even) td {
  background: $lightGray;
}

.app-static-content table th,
.app-table th {
  text-align: left;
  font-size: 13px;
  color: $gray2;
  padding: 8px;
  border-bottom: 1px solid $lightGray3;
  background: $lightGray4;
}

.app-table--secondary-wrapper {
  overflow-x: auto;
  position: relative;
}

.app-table--secondary {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}

.app-table--secondary tbody tr {
  box-shadow: $boxShadow;
}

.app-table--secondary th,
.app-table--secondary td {
  min-width: 90px;
  width: 90px;
  padding: 5px 10px;
  height: 60px;
}

.app-table--secondary th:first-of-type {
  text-align: left;
}

.app-table--secondary th {
  color: $gray2;
  font-weight: normal;
  font-size: 12px;
  border-bottom: 1px solid $lightGray2;
  text-align: center;
}

.app-table--secondary td {
  border: 1px solid $lightGray2;
  background: $white;
  font-size: 14px;
}

.app-table--secondary th.cell--checkbox,
.app-table--secondary td.cell--checkbox {
  width: 52px;
  min-width: 52px;
}

.app-table--secondary th:nth-of-type(2):last-of-type,
.app-table--secondary td:nth-of-type(2):last-of-type {
  width: 100%;
}

.app-table--secondary td .button--primary {
  padding: 11px 15px;
}

.app-table--secondary td a {
  color: $black;
  text-decoration: none;
}

.app-table--secondary td a.button--primary {
  color: $white;
}

.table__loading-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: $white3;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  min-height: 80px;
}

.table__loading-wrapper--empty {
  position: relative;
}