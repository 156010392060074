@import "../../styles/variables";

.app-breadcrumbs {
  position: relative;
  top: 80px;
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.breadcrumbs {
  list-style: none;
  padding: 0;
  font-size: 13px;
  width: 100%;
  white-space: nowrap;
  display: flex;
}

.breadcrumbs__item {
  display: inline-block;
  max-width: 100%;
}

.breadcrumbs__item:last-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumbs__item a {
  color: $gray4;
  text-decoration: none;
  max-width: 100%;
}

.breadcrumbs__item a span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;

  @media screen and (max-width: $mobile) {
    max-width: calc(100% - 80px);
  }
}

.breadcrumbs__item a:hover {
  color: $blue;
}

.breadcrumbs__separator {
  margin: 0 5px;
}

.breadcrumbs__item:last-of-type .breadcrumbs__separator {
  display: none;
}