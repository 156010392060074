@import "./variables";

// Search

.icon--search-gray {
  width: 34px;
  height: 34px;
  @include background("icons/search-gray.svg", center, no-repeat);
  background-size: 18px;
  display: inline-block;
  cursor: pointer;

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.app__search-field .icon--search-gray {
  @media screen and (max-width: $mobile) {
    display: inline-block;
  }
}

.icon--close {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  @include background("icons/close.svg", center, no-repeat);
  background-size: 12px;
  display: inline-block;
  cursor: pointer;
  opacity: 1;

  @media screen and (max-width: $tablet) {
    margin: 0;
    background-size: 14px;
  }
}

.search .icon--close {
  width: 34px;
  height: 34px;
  margin-right: 0;
}

.icon--close--hidden {
  width: 34px;
  height: 34px;
  @include background("icons/close.svg", center, no-repeat);
  background-size: 12px;
  display: inline-block;
  cursor: pointer;
  opacity: 0;
}

.icon--goal {
  display: inline-block;
  @include background("icons/goal.svg", center, no-repeat);
  width: 29px;
  min-width: 29px;
  height: 29px;
  background-size: 29px;
  margin: 0 15px 0 10px;
}

.icon--information {
  display: inline-block;
  @include background("icons/information.svg", center, no-repeat);
  width: 23px;
  height: 23px;
  background-size: 23px;
  margin-right: 10px;
}

.box__icon--1 {
  @include background("icons/solution.svg", center, no-repeat);
  width: 80px;
  height: 62px;
  background-size: 62px;
  margin: 0 auto;
}

.box__icon--2 {
  @include background("icons/method.svg", center, no-repeat);
  width: 80px;
  height: 62px;
  background-size: 62px;
  margin: 0 auto;
}

.box__icon--3 {
  @include background("logos/scope.svg", center, no-repeat);
  width: 100px;
  height: 62px;
  background-size: contain;
  margin: 0 auto;
}

// Register

.register-type__icon--1 {
  @include background("register/register-icon-1.svg", center center, no-repeat);
  width: 100px;
  height: 100px;
  background-size: 65px;
  margin: 0 auto;
}

.register-type__icon--2 {
  @include background("register/register-icon-2.svg", center center, no-repeat);
  width: 100px;
  height: 100px;
  background-size: 80px;
  margin: 0 auto;
}

.register-type--active .register-type__icon--1 {
  @include background("register/register-icon-1-white.svg", center center, no-repeat);
  background-size: 65px;
}

.register-type--active .register-type__icon--2 {
  @include background("register/register-icon-2-white.svg", center center, no-repeat);
  background-size: 80px;
}

// Search boxes

.search-box__icon--1 {
  @include background("icons/cup.svg", left center, no-repeat);
  width: 45px;
  height: 62px;
  background-size: 25px;
}

.search-box__icon--2 {
  @include background("icons/check.svg", left center, no-repeat);
  width: 45px;
  height: 62px;
  background-size: 25px;
}

.search-box__icon--3 {
  @include background("/icons/20years.svg", left center, no-repeat);
  width: 45px;
  height: 62px;
  background-size: 35px;
  margin-right: 10px;
}

// Search results

.icon--winner {
  @include background("/icons/nominated.svg", center, no-repeat);
  width: 38px;
  height: 55px;
  background-size: 38px;
  position: absolute;
  right: -20px;
  top: -2px;
  z-index: 2;

  @media screen and (max-width: $tablet) {
    right: 15px;
  }

  @media screen and (max-width: $mobile) {
    right: 0;
  }
}

.fund__box .icon--winner {
  right: 15px;
}

// Fund details news

.icon--group {
  @include background("icons/group.svg", center, no-repeat);
  width: 50px;
  height: 50px;
  background-size: 42px;
}

.icon--profit {
  @include background("icons/profit.svg", center, no-repeat);
  width: 50px;
  height: 50px;
  background-size: 42px;
}

.icon--files {
  display: inline-block;
  @include background("icons/files.svg", center, no-repeat);
  width: 50px;
  height: 50px;
  background-size: auto 42px;
}

// Fund details boxes

.icon--bar-chart {
  display: inline-block;
  @include background("icons/bar-chart.svg", center, no-repeat);
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-right: 10px;
}

.icon--data {
  display: inline-block;
  @include background("icons/data.svg", center, no-repeat);
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-right: 10px;
}

.icon--chart-analytics {
  display: inline-block;
  @include background("icons/chart-analytics.svg", center, no-repeat);
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-right: 10px;
}

.icon--warning {
  display: inline-block;
  @include background("icons/warning.svg", center, no-repeat);
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-right: 10px;
}

.icon--warning-blue {
  display: inline-block;
  @include background("icons/warning-blue.svg", center, no-repeat);
  width: 21px;
  height: 21px;
  background-size: 21px;
  margin-right: 10px;
}

.icon--chess-piece {
  display: inline-block;
  @include background("icons/chess-piece.svg", center, no-repeat);
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-right: 10px;
}

.icon--verified {
  display: inline-block;
  @include background("icons/verified.svg", center, no-repeat);
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-right: 10px;
}

.icon--file {
  display: inline-block;
  @include background("icons/file.svg", center, no-repeat);
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-right: 10px;
}

.icon--compare-white {
  display: inline-block;
  @include background("icons/compare-white.svg", center, no-repeat);
  width: 16px;
  height: 16px;
  background-size: 16px;
  margin-right: 10px;
}

.icon--compare-blue {
  display: inline-block;
  @include background("icons/compare-blue.svg", center, no-repeat);
  width: 16px;
  height: 16px;
  background-size: 16px;
  margin-right: 10px;
}

// Arrows

.icon--arrow-right-blue {
  width: 8px;
  height: 12px;
  @include background("icons/arrow-blue.svg");
  background-size: 6px;
  display: inline-block;
  margin-left: 7px;
}

.icon--arrow-left-blue {
  width: 8px;
  height: 12px;
  @include background("icons/arrow-blue.svg");
  background-size: 6px;
  display: inline-block;
  margin-right: 7px;
  transform: rotate(180deg);
}

.icon--arrow-right-orange {
  width: 8px;
  height: 12px;
  @include background("icons/arrow-orange.svg");
  background-size: 6px;
  display: inline-block;
  margin-left: 7px;
}

.icon--arrow-right-gray {
  width: 8px;
  height: 18px;
  @include background("icons/arrow-gray.svg");
  background-size: 8px;
  display: inline-block;
  transform: translateX(-12px);
  transition: transform ease-in 0.1s;

  @media screen and (max-width: $mobile) {
    width: 12px;
  }
}

.icon--arrow-down-gray {
  width: 14px;
  height: 14px;
  @include background("icons/arrow-gray.svg", center);
  background-size: 8px;
  display: inline-block;
  transform: rotate(90deg);
  transition: transform ease-in 0.1s;

  @media screen and (max-width: $mobile) {
    width: 12px;
  }
}

.icon--arrow-down-white {
  width: 15px;
  height: 15px;
  @include background("icons/arrow-white.svg");
  background-size: 8px 15px;
  display: inline-block;
  margin-left: 10px;
  transform: rotate(90deg);
  position: relative;
  top: 4px;
}

.icon--arrow-up-white {
  width: 15px;
  height: 15px;
  @include background("icons/arrow-white.svg");
  background-size: 8px 15px;
  display: inline-block;
  margin-left: 10px;
  transform: rotate(270deg);
  position: relative;
  top: -4px;
}

.icon--arrow-right-white {
  width: 10px;
  height: 10px;
  @include background("icons/arrow-white.svg");
  background-size: 8px 10px;
  display: inline-block;
  margin-left: 10px;
  transform: rotate(0);
  position: relative;
  top: 0;
}

.icon--arrow-down-black {
  width: 15px;
  height: 15px;
  @include background("icons/arrow-black.svg");
  background-size: 8px 15px;
  display: inline-block;
  margin-left: 10px;
  transform: rotate(90deg);
  position: relative;
  top: 4px;
}

.icon--arrow-up-black {
  width: 15px;
  height: 15px;
  @include background("icons/arrow-black.svg");
  background-size: 8px 15px;
  display: inline-block;
  margin-left: 10px;
  transform: rotate(270deg);
  position: relative;
  top: -4px;
}

.icon--arrow-right-black {
  width: 10px;
  height: 10px;
  @include background("icons/arrow-black.svg");
  background-size: 8px 10px;
  display: inline-block;
  margin-left: 10px;
  transform: rotate(0);
  position: relative;
  top: 0;
}

// Funds in focus

.icon--funds-in-focus-0 {
  width: 28px;
  height: 28px;
  @include background("funds-in-focus/0-white.svg");
  background-size: 28px;
  display: inline-block;
  margin-right: 10px;
}

.funds-in-focus-category--active .icon--funds-in-focus-0 {
  @include background("funds-in-focus/0-orange.svg");
}

.icon--funds-in-focus-1 {
  width: 28px;
  height: 28px;
  @include background("funds-in-focus/1-white.svg");
  background-size: 28px;
  display: inline-block;
  margin-right: 10px;
}

.funds-in-focus-category--active .icon--funds-in-focus-1 {
  @include background("funds-in-focus/1-orange.svg");
}

.icon--funds-in-focus-2 {
  width: 28px;
  height: 28px;
  @include background("funds-in-focus/2-white.svg");
  background-size: 28px;
  display: inline-block;
  margin-right: 10px;
}

.funds-in-focus-category--active .icon--funds-in-focus-2 {
  @include background("funds-in-focus/2-orange.svg");
}

.icon--funds-in-focus-3 {
  width: 28px;
  height: 28px;
  @include background("funds-in-focus/3-white.svg");
  background-size: 28px;
  display: inline-block;
  margin-right: 10px;
}

.funds-in-focus-category--active .icon--funds-in-focus-3 {
  @include background("funds-in-focus/3-orange.svg");
}

.funds-in-focus__info .info__icon--1 {
  width: 40px;
  height: 40px;
  @include background("icons/group.svg");
  background-size: 30px;
  display: inline-block;
}

.funds-in-focus__info .info__icon--2 {
  width: 40px;
  height: 40px;
  @include background("icons/profit.svg");
  background-size: 30px;
  display: inline-block;
}

.funds-in-focus__info .info__icon--3 {
  width: 40px;
  height: 40px;
  @include background("icons/files.svg");
  background-size: 30px;
  display: inline-block;
}

.icon--analysis {
  display: inline-block;
  @include background("icons/analysis.svg", center);
  width: 29px;
  min-width: 29px;
  height: 29px;
  background-size: 29px;
  margin: 0 15px 0 10px;
}

// Asset manager

.icon--asset-manager-1 {
  display: inline-block;
  @include background("icons/asset-manager-icon-1.png", center);
  width: 20px;
  height: 27px;
  margin-right: 10px;
  background-size: auto 20px;
}

.icon--asset-manager-2 {
  display: inline-block;
  @include background("icons/asset-manager-icon-2.png", center);
  width: 20px;
  height: 27px;
  margin-right: 10px;
  background-size: 20px;
}

.icon--asset-manager-3 {
  display: inline-block;
  @include background("icons/asset-manager-icon-3.png", center);
  width: 20px;
  height: 27px;
  margin-right: 10px;
  background-size: 20px;
}

.icon--asset-manager-4 {
  display: inline-block;
  @include background("icons/asset-manager-icon-4.png", center);
  width: 20px;
  height: 27px;
  margin-right: 10px;
  background-size: 20px;
}

.icon--asset-manager-5 {
  display: inline-block;
  @include background("icons/survey.svg", center);
  width: 27px;
  height: 27px;
  margin-right: 10px;
  background-size: 27px;
}

.icon--asset-manager-6 {
  display: inline-block;
  @include background("icons/asset-manager-icon-6.png", center);
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-size: 20px;
}

// Payments

.sepa-icon {
  display: inline-block;
  @include background("payments/sepa.jpg", center);
  width: 180px;
  height: 70px;
}

.payment-method.payment-method--disabled .sepa-icon {
  display: inline-block;
  @include background("payments/sepa-black-white.jpg", center);
  width: 180px;
  height: 70px;
}

.giropay-icon {
  display: inline-block;
  @include background("payments/giropay.jpg", center);
  width: 180px;
  height: 70px;
  background-size: contain;
}

.payment-method.payment-method--disabled .giropay-icon {
  display: inline-block;
  @include background("payments/giropay-black-white.jpg", center);
  width: 180px;
  height: 70px;
  background-size: contain;
}

.cards-icon {
  display: inline-block;
  @include background("payments/cards.png", center);
  width: 180px;
  height: 70px;
  background-size: 100%;
}

// Other

.icon--bookmark-orange {
  display: inline-block;
  @include background("icons/bookmark-orange.svg", center, no-repeat);
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-right: 10px;
}
