@import "../../styles/variables";

.loading__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  width: 100%;
}

.loading__indicator {
  border: 4px solid $lightGray2;
  border-top: 4px solid $blue;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading__wrapper.loading__wrapper--small {
  margin: 0;
  width: 50px;
}

.loading__wrapper--small .loading__indicator {
  width: 20px;
  height: 20px;
  border: 2px solid $lightGray2;
  border-top: 2px solid $blue;
}