@import "./variables";

.print {
  display: none;
}

.print__logo {
  margin-top: 30px;
}

@media print {
  body,
  body::before,
  .selected-fund__header .select__control,
  .fund--selected,
  .fund__number,
  .fund__number .fund__number-inner,
  .fund--selected .fund__name,
  .fund--selected .fund__name,
  .fund--selected .fund__rating,
  .fund--selected .fund__points,
  .fund--selected .fund__risk,
  .fund--selected .fund_performance,
  .fund--selected .fund__more,
  .fund-bar__label {
    background: $white !important;
    background-color: $white !important;
    background-image: none !important;
  }
  
  .app-main {
    margin: 0 !important;
    padding: 0 !important;
    background: $white !important;
    background-color: $white !important;
  }

  .app-header,
  .app-footer,
  .funds-list__search,
  .selected-fund .fund__more,
  .fund__col.fund__more,
  .icon--bar-chart-blue,
  .fund--selected .fund__number::before,
  .fund--selected::before,
  .select__indicators,
  .selected-fund__header .fund__help,
  .fund__print,
  .no-print,
  .fund .fund__number::before,
  .funds-list__controls,
  .funds-list-header,
  .app__pagination,
  .app-table--secondary th:first-of-type,
  .app-table--secondary td:first-of-type,
  .app-table--secondary th:last-of-type,
  .app-table--secondary td:last-of-type,
  .fund-items__dots,
  .scroll-to,
  .fund-details__links,
  .fund__box .icon,
  .fund-bars.fund__box::before,
  .fund-bars.fund__box::after,
  .fund-bar__label::after,
  .driver__list .icon,
  .chart__controls .button--primary,
  .chart__toggle,
  .fund-charts .link.link--right,
  .news__link,
  .scale--active::before,
  .premium__header,
  .premium__footer,
  .premium__section-header .button,
  .app-top--premium,
  .selected-fund .fund-rating__card .fund__rating-scale,
  .fund__rating-scale,
  .toggle-average,
  .fund__box .fa-info-circle,
  .app-table--secondary .cell--documents,
  .app-table--secondary .cell--watchlist,
  .app-table--secondary .cell--rank .button,
  .app-bg,
  .sort__wrapper,
  .perf-risk__legend-wrapper,
  .icon--goal,
  .fund__box.fund__box--fund-bars:before,
  .no-fund-bars-info--overlay .button,
  .no-fund-bars-info .button,
  .row--max_drawdown,
  .fund__box.fund__box--fund-bars:after,
  .selected-fund__header .fund__perf-risk .fa-area-chart,
  .perf-risk-bar__wrapper .fa-area-chart,
  .premium-contact,
  .app-breadcrumbs,
  .app-content,
  .fund__institutional,
  .cookie-info,
  .app-table--secondary.app-table--watchlist .button,
  .app-content__body,
  .app-modal--funds-list,
  .funds__back,
  .rating-date__container,
  .loading__indicator {
    display: none !important;
  }

  .perf-risk-bar {
    margin-right: 0 !important;
  }

  .perf-risk-bar__label {
    font-size: 10px!important;
    width: 60px!important;
  }

  .selected-fund .perf-risk-bar {
    margin-right: 50px !important;
  }

  .selected-fund .perf-risk-bar--ratio {
    margin: 0 !important;
  }

  .box__content table th:first-of-type,
  .box__content table td:first-of-type {
    min-width: 0 !important;
  }

  .premium__content .app-table--secondary .cell--name {
    min-width: 100px !important;
    width: 100px !important;
  }

  .fund__rating img {
    position: relative !important;
    top: 2px !important;
  }

  .app-table--secondary {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .scale {
    height: 45px !important;
  }

  .print {
    display: block;
  }

  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1;
  }

  .qualitative-legend,
  .quantitative-legend {
    margin-top: 4px !important;
  }

  .fund__header {
    border-bottom: 1px solid $lightGray2 !important;
  }

  .fund {
    margin: 0 !important;
    border: none !important;
    border-bottom: 2px solid $lightGray2 !important;
    border-radius: 0 !important;
    height: 80px !important;
  }

  .selected-fund .fund__number-inner {
    color: $black !important;
    font-size: 20px !important;
    background: $white !important;
  }

  .fund--selected .fund__number .fund__number-inner,
  .fund .fund__number {
    height: auto !important;
    top: 0 !important;
    border: none !important;
    color: $black !important;
    font-size: 20px !important;
    background: $white !important;
  }

  .fund .fund__number {
    border-right: 1px solid $lightGray2 !important;
    width: 85px !important;
    min-width: 0 !important;
  }

  .fund__header .fund__number {
    width: 85px !important;
    min-width: 0 !important;
  }

  .selected-fund__header .select,
  .fund__header .select {
    margin-left: 0px !important;
  }

  .fund__header .fund__col {
    border: none !important;
  }

  .fund__container .fund__header .fund__name {
    width: calc(100% - 750px) !important;
    min-width: 0 !important;

    @media (orientation: landscape) {
      width: calc(100% - 300px) !important;
    }
  }

  .fund__col.fund__name {
    flex-direction: column !important;
    align-items: flex-start !important;
    align-content: center  !important;
    justify-content: space-around !important;
  }

  .fund .fund__name {
    width: calc(100% - 300px) !important;
    min-width: 0 !important;
  }

  .selected-fund__header .fund__perf-risk {
    width: 350px !important;
    white-space: nowrap !important;
  }

  .fund__header .fund__perf-risk {
    width: 350px !important;
    white-space: nowrap !important;
    justify-content: flex-start !important;
    padding-left: 10px !important;
  }

  .fund > .fund__rating,
  .fund__rating {
    width: 70px !important;
  }

  .fund__points {
    width: 75px !important;
  }

  .premium__content .fund {
    margin: 0 !important;
  }

  .premium__content .fund__header .fund__name,
  .premium__content .fund .fund__name {
    width: calc(100% - 200px) !important;
  }

  .fund .fund__name,
  .chart__title {
    font-size: 16px !important;
  }

  .fund .rating--qualitative {
    top: 0 !important;
  }

  .fund--selected {
    border: none !important;
    border-bottom: 2px solid $lightGray2 !important;
  }

  .selected-fund.app-card {
    border: 2px solid $lightGray2 !important;
  }

  .fund .fund__col {
    height: 78px !important;
    font-size: 14px !important;
  }

  .css-vj8t7z,
  .selected-fund__header .fund__col,
  .funds-list-table__wrapper,
  .app-card,
  .select__control {
    border: none !important;
  }

  .selected-fund.app-card,
  .fund,
  .funds__header,
  .funds__legend,
  .funds-list-table__wrapper,
  .app-table--secondary tbody tr,
  .app-card,
  .fund-bar,
  .fund-bar__label {
    box-shadow: none !important;
  }

  .funds-list-table__wrapper,
  .app-top__inner,
  .app__title.fund__name,
  .app-table--secondary tbody tr,
  .box__content,
  .driver__text,
  .fund-charts,
  .news__date,
  .fund__news,
  .premium__inner .app-card {
    margin: 0 !important;
    padding: 0 !important;
  }

  .fund__boxes {
    display: table!important;
  }

  .fund__box, .fund__box.app-card {
    display: inline-block!important;
  }

  .fund__box--fund-bars,
  .fund-chart,
  .fund__header,
  .funds__legend-container,
  .fund__box {
    clear: both !important;
    page-break-before: always !important;
  }

  .fund__box--crash-drawdown-chart {
    display: block !important;
    page-break-before: auto !important;
  }

  .fund-chart.app-card {
    padding: 0!important;
  }

  .chart__area {
    display: flex !important;
    @include background("crash-drawdown-bg-de.png");
    background-size: contain!important;
    width: 710px !important;
    height: 460px !important;
    position: relative!important;
    margin: 0!important;
    overflow: hidden;

    @media (orientation: landscape) {
      width: 1000px !important;
      height: 647px !important;
    }
  }

  .crash-drawdown__box {
    padding: 0;
    background-position: 8px 50px!important;

    @media (orientation: portrait) {
      transform: scale(0.7) !important;
    }
  }

  .crash-drawdown__box--2,
  .crash-drawdown__box--3 {
    top: 120px !important;
  }

  .crash-drawdown__box--0 {
    left: 15px !important;

    @media (orientation: portrait) {
      top: 70px !important;
    }

    @media (orientation: landscape) {
      left: 30px !important;
    }
  }

  .crash-drawdown__box--1 {
    @media (orientation: portrait) {
      top: 70px !important;
     left: 150px !important;
    }

    @media (orientation: landscape) {
      left: 240px !important;
    }
  }

  .crash-drawdown__box--2 {
    top: 180px !important;
    left: 400px !important;

    @media (orientation: landscape) {
      top: 300px !important;
      left: 600px !important;
    }
  }

  .crash-drawdown__box--3 {
    top: 180px !important;
    left: 530px !important;

    @media (orientation: landscape) {
      top: 300px !important;
      left: 800px !important;
    }
  }

  .fund-bars__legend-item {
    margin: 0!important;
  }

  .fund-bar__label span:first-of-type {
    font-size: 9px!important;
  }

  .fund__boxes--wholesale .fund__box--scope-rating,
  .fund__box--content,
  .fund__box--fund-facts,
  .fund__box--strategy,
  .fund__box--crash-drawdown-chart,
  .fund__box--driver {
    max-width: 100%!important;
    width: 100%!important;
  }

  .app-body--md .fund__boxes--wholesale .fund__box--scope-rating {
    width: calc(33.33333% - 15px) !important;
  }

  .fund__box--performance,
  .fund__box--risk {
    max-width: 50%!important;
    width: 50%!important;
  }

  .fund-chart,
  .fund__box,
  .fund,
  .app-card {
    page-break-inside: avoid !important;
  }

  .app-card.funds-list-table__wrapper {
    page-break-inside: initial !important;
  }

  .app-table--secondary td.cell--rank {
    background-color: $white!important;
  }

  .app-table--secondary-wrapper {
    overflow: hidden !important;
  }

  .funds-list-table__wrapper .app-table--secondary thead {
    display: table-header-group !important;
  }

  .funds-list-table__wrapper .app-table--secondary tbody {
    display: table-row-group !important;
    overflow: hidden !important;
    max-height: none!important;
  }

  .app-table--secondary .cell--name,
  .app-table--secondary .cell--isin,
  .app-table--secondary .cell--peergroup_name,
  .app-table--secondary td,
  .app-table--secondary th,
  .app-table--secondary th:first-of-type,
  .app-table--secondary td:first-of-type {
    min-width: 0 !important;
    width: auto !important;
  }

  .watchlist-fund .fund_performance,
  .app-table--secondary.app-table--watchlist .fund_performance {
    min-width: 70px !important;
    width: auto !important;
  }

  .app-table--secondary.app-table--watchlist  {
    margin-top: 50px !important;
  }

  .app-table--secondary.app-table--watchlist th,
  .app-table--secondary.app-table--watchlist td  {
    font-size: 10px !important;
  }

  .prev-rank__label {
    border: none !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    font-size: 10px !important;
  }

  .app-table--secondary.app-table--watchlist .cell--name {
    display: table-cell !important;
    max-width: 130px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .esg-value {
    width: 20px !important;
  }

  .app-top .app__title.fund__name,
  .app-top .app__subtitle {
    min-height: 0 !important;
    color: $black !important;
    text-align: center !important;
    margin: 0 auto!important;
  }

  .app-top .app__title.fund__name,
  .points__value,
  .points__range {
    font-size: 20px !important;
  }

  .fund__position,
  .fund__peergroup-name {
    color: $black !important;
  }

  .fund__peergroup-name {
    margin-bottom: 15px !important;
  }

  .funds__legend {
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
  }

  .funds__legend-item {
    width: 25% !important;
    justify-content: flex-start !important;
    margin-top: 10px !important;
  }

  .funds__legend-item:last-of-type .label {
    max-width: 20px !important;
  }

  .funds__legend-item .rating--quantitative {
    margin: 0 9px !important;
  }

  .fund__col.fund__perf-risk {
    width: 350px !important;
  }

  .selected-fund .points-legend {
    margin-left: 10px !important;
    width: 150px !important;
  }

  .selected-fund .points__value,
  .selected-fund .points__range {
    font-size: 16px !important;
  }

  .selected-fund__header .fund__number {
    padding-top: 0 !important;
  }

  .selected-fund__header .fund__name {
    width: 160px !important;
    justify-content: flex-start !important;
    padding-top: 0 !important;
  }

  .selected-fund .fund__name {
    width: 160px !important;
  }

  .selected-fund__header .fund__position {
    justify-content: flex-start !important;
    padding: 0 !important;
  }

  .selected-fund .fund-rating__card {
    width: 150px !important;
    background: $white !important;
  }

  .selected-fund__header .fund__rating {
    width: 160px !important;
    margin: 0 !important;
    padding-top: 0 !important;
  }

  .selected-fund__header .fund__perf-risk > div:first-of-type {
    padding-top: 0 !important;
  }

  .selected-fund__header .fund__risk {
    width: 180px !important;
  }

  .selected-fund__header {
    padding: 0 !important;
  }

  .fund__name span.fund__logo {
    width: 60px !important;
    margin-right: 5px !important;
  }

  .fund__name a > span img {
    width: 60px !important;
  }

  .legend--quantitative,
  .legend--qualitative {
    margin: 5px 0 !important;
  }

  .fund__boxes {
    margin-top: 40px !important;
  }

  .legend-container {
    margin-top: 10px !important;
    flex-direction: column !important;
  }

  .box__name {
    padding-left: 0 !important;
    padding-bottom: 8px !important;
    font-size: 12px !important;
    height: auto !important;
  }

  .fund-bar__average {
    font-size: 10px !important;
  }

  .fund-bar__label {
    margin-bottom: 0 !important;
  }

  .fund-bar__label-value {
    font-size: 12px !important;
  }

  .app-top .app__subtitle {
    margin-top: 5px !important;
    font-size: 14px !important;
    min-height: 0 !important;
  }

  .box__rating .print {
    text-align: center !important;
  }

  .box__rating .print img {
    margin: 0 auto !important;
    margin-top: 30px !important;
  }

  .fund-bar,
  .fund-bars__legend,
  .legend,
  .legend__color,
  .perf-risk-bar {
    -webkit-print-color-adjust: exact !important;
  }

  .box__content table th,
  .box__content table td {
    padding: 5px !important;
  }

  .news__box {
    padding-bottom: 7px !important;
  }

  .fund-bar__wrapper {
    width: 23% !important;
  }

  .highcharts-container,
  .highcharts-root {
    width: 100% !important;
    height: auto !important;
  }

  .premium__content,
  .box__content {
    page-break-after: always !important;
  }

  .premium__content {
    width: 100% !important;
  }

  .premium__inner {
    margin-top: 80px !important;
    flex-direction: column !important;
  }

  .box__content .highcharts-container {
    margin-top: 20px !important;
  }

  .selected-fund__inner {
    padding: 15px 0 !important;
  }

  .funds-list-table__wrapper .app-table--secondary tbody {
    max-height: none!important;
  }

  
  .fund__market {
    bottom: 45px !important;
  }

  .app-body--md .app-wrapper{
    padding-top: 30px;
  }
}
