@import "./variables";

.app__tabs {
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  color: $lightGray3;
  font-weight: bold;
  font-size: 14px;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.funds-list__label {
  padding: 10px 15px 10px 0;
  color: $blue;

  @media screen and (max-width: $mobile) {
    padding: 10px 0;
  }
}

.app__tab {
  cursor: pointer;
  padding: 10px 15px;
  transition: color ease-in 0.1s;

  @media screen and (max-width: $mobile) {
    padding: 10px 0;
  }
}

.app__tab:hover {
  color: $blue;
}

.app__tab--active {
  color: $blue;
}

.app__tab--active::after {
  content: "";
  width: 100%;
  height: 1px;
  background: $blue;
  display: block;
  margin-top: 10px;
}
