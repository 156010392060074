@import "./variables";

// Sizes

.button.button--large {
  padding: 10px 50px;
}

.button.button--medium {
  padding: 11px 20px;
}

.button.button--small {
  padding: 11px;
}

// Colors

.button.button--primary {
  color: $white;
  background: $blue;
}

.button.button--primary:disabled {
  opacity: 0.8;
  cursor: initial;
}

.button.button--primary:hover {
  background: $blueHover;
}

.button.button--primary-inverted {
  color: $black;
  background: $white;
  border: 1px solid $white;
  transition: background ease-in 0.15s;
}

.button.button--primary-inverted:hover {
  color: $blue;
}

.button.button--primary-orange {
  color: $white;
  background: $orange;
  border: 1px solid $orange;
}

.button.button--primary-orange:hover {
  background-color: $darkOrange;
  border-color: $darkOrange;
}

.button.button--secondary {
  color: $blue;
  background: transparent;
  border: 1px solid $lightGray3;
}

.button.button--secondary:hover {
  background: $blueHover;
  border: 1px solid $blueHover;
  color: $white;

  @media (any-pointer: coarse) {
    color: $blue;
    background: transparent;
    border: 1px solid $lightGray3;
  }
}

.button.button--secondary-orange {
  color: $orange;
  background: transparent;
  border: 1px solid $orange;
}

.button.button--secondary-orange:hover {
  background: $orange;
  border: 1px solid $orange;
  color: $white;

  @media (any-pointer: coarse) {
    color: $orange;
    background: transparent;
    border: 1px solid $lightGray3;
  }
}

.button.button--active {
  background: $blue;
  border-color: $blue;
  color: $white;
}

// Icons


.box__name .button--icon-left .fa,
.box__name .button--icon-left .icon,
.button--icon-left .fa,
.button--icon-left .icon {
  margin-right: 10px;
  margin-left: 0;
}

.box__name .button--icon-right .fa,
.box__name .button--icon-right .icon,
.button--icon-right .fa,
.button--icon-right .icon {
  margin-left: 10px;
  margin-right: 0;
}


.button {
  border: none;
  background: $lightGray3;
  border-radius: $borderRadius;
  cursor: pointer;
  padding: 10px 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 13px;
  font-family: $font;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  transition: background ease-in 0.15s;
}

.link::after,
.button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: $white2;
  opacity: 0;
  border-radius: 50%;
  transform: scale(0.5, 0.5) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  20% {
    transform: scale(25, 25);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.link:focus:not(:active)::after,
.button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

// Custom margins

.filter-search-results form[name="filterSearch"] .button,
.conference__content .button,
.modal__footer .button {
  margin-right: 10px;
  max-height: 35px;
}

.funds-list__controls-inner .button,
.funds-list-header .button:last-of-type,
.chart__controls .button,
.cookie-info__footer .button,
.news__views-switch .button {
  margin-left: 10px;
}

.box__no-content .button {
  margin-bottom: 30px;
}

.publications__empty .button {
  margin-bottom: 20px;
}

.payment-invoice .button {
  margin: 0 15px;
}

.fund__box .no-info-message .button,
.no-info-message .button {
  margin: 5px auto 0;
}

.wholesale-page .button {
  margin-top: 30px;
}

.no-info-message.no-info-message--rating .button {
  margin-top: 20px;
}

.wholesale-page .button,
.register-success .button {
  margin-top: 15px;
}

.aif-documents-charts--login .button {
  margin-top: 10px;
}

.fund__more .button--secondary {
  margin-top: 5px;

  @media screen and (max-width: $tablet) {
    margin-top: 5px;
    margin-left: 5px;
  }

  @media screen and (max-width: $mobileXs) {
    margin-top: 5px;
    margin-left: 0;
  }
}

.no-info-message .no-info-message__controls .button {
  margin: 20px 10px;
}

.filter-search-results form[name="filterSearch"] .button {
  @media screen and (max-width: $midDesktop) {
    padding: 10px 11px;
  }
}

.modal__footer .button {
  @media screen and (max-width: $mobile) {
    margin-bottom: 10px;
  }
}

.premium__footer .button {
  margin-left: 20px;

  @media screen and (max-width: $tablet) {
    margin: 5px 0;
  }
}

.selected-fund .fund__more .button {
  margin: 3px 0;

  @media screen and (max-width: $tablet) {
    margin: 0 5px;
  }

  @media screen and (max-width: $mobile) {
    margin: 3px 0;
  }
}

.filter-search__controls .button {
  margin: 10px 10px 0 0;
}

// Custom width

.watchlist-fund .fund__more .button {
  width: 120px;

  @media screen and (max-width: $mobile) {
    margin: 0 3px;
  }
}

.fund__more .button {
  width: 110px;
}

.autocomplete__inner .button {
  width: 136px;
}

// Custom padding - Links

.watchlist-fund .button,
.rating-in-focus .button,
.conferences-list .conference__bottom .button,
.app-table--fund-documents .button {
  padding: 11px;
}

.rating-in-focus__header .button {
  padding: 11px 20px;
}

// Custom colors

.fund-bar__wrapper .button:hover,
.app-table--fund-documents a.button {
  color: $blue;
}

.app-table--fund-documents a.button:hover {
  color: $white;
}

.home-banner__footer .button {
  background-color: $white;
  color: $orange;

  @media screen and (max-width: $mobile) {
    margin-top: 20px;
  }
}

// Custom others

.asset-manager-profile__box .box__name .button {
  @media screen and (max-width: $mobile) {
    margin-top: 20px;
  }
}

.box__name-controls .button {
  @media screen and (max-width: $mobile) {
    padding: 11px 20px;
    margin: 15px 0;
  }
}

.aif-banner .button {
  @media screen and (max-width: $midDesktop) {
    margin-top: 10px;
  }
}

.rating-in-focus .button {
  margin: 15px auto 0;
}

.fund__prev-rank+.button {
  font-size: 11px;
}

.home-box .box__footer .button,
.box__footer .button {
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  width: 140px;
  margin: 0 auto;
}

.premium__section-header .button {
  padding: 11px 18px;
  margin: 10px 5px 0 0;

  @media screen and (max-width: $midDesktop) {
    margin: 10px 8px 0 0;
  }
}


.fund-bar__wrapper .button {
  background: $white;
  width: 100px;
  margin: 0 auto;
}

.compare-funds .button.button--primary {
  padding: 11px 20px;

  @media screen and (max-width: $tablet) {
    margin-top: 5px;
  }
}

.chart__controls .button {
  padding: 10px 20px;

  @media screen and (max-width: 600px) {
    margin: 10px 0;
    display: block;
  }
}

.chart__controls .label {
  margin: 0 10px;

  @media screen and (max-width: $mobile) {
    margin: 3px 0;
  }
}

.funds-list-header .button {
  align-items: center;
  padding: 11px 20px;

  @media screen and (max-width: $mobile) {
    margin: 10px 0;
  }
}

.funds-list__controls-inner .button {
  @media screen and (max-width: $mobile) {
    margin: 0 10px 25px 0;
  }
}

.app-table--secondary .cell--documents .button,
.app-table--secondary .cell--watchlist .button {
  padding: 11px 15px;
}

.app-table--secondary .cell--watchlist .button .fa {
  margin-right: 5px;
}

.app-modal--funds-list .app-content__footer .button {
  padding: 11px 40px;

  @media screen and (max-width: $mobile) {
    width: 140px;
    margin-bottom: 10px;
    justify-content: center;
  }
}

.news__search .button {
  margin-left: 15px;
  margin-top: 38px;

  @media screen and (max-width: $mobile) {
    padding: 0;
    width: 38px;
    height: 38px;
    @include background("icons/search-white.svg", center, no-repeat, $blue);
    background-size: 20px;
    margin-top: 10px;
    margin-left: 0;
    position: absolute;
    right: 0;
    bottom: 0;

    span {
      display: none;
    }
  }
}

.cookie-info__footer .button {
  min-width: 100px;

  @media screen and (max-width: $mobile) {
    margin: 10px 0 0 0;
  }
}