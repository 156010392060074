@import "./variables";

.publications-list--slick {
  .slick-prev {
    z-index: 2;
    width: 36px;
    height: 36px;

    @media screen and (max-width: $mobile) {
      left: -20px;
    }
  }

  .slick-next {
    z-index: 2;
    right: -27px;
    width: 36px;
    height: 36px;

    @media screen and (max-width: $mobile) {
      right: -4px;
    }
  }

  .slick-prev:before, .slick-next:before {
    font-size: 36px;
    color: $lightGray3;
  }

  .slick-dots li button:before {
    font-size: 12px;
  }

  .slick-dots li button:focus:before ,
  .slick-dots li.slick-active button:before {
    color: $blue;
    opacity: 1;
  }

  .slick-dots li button:hover:before {
    color: $lightBlue2;
  }
}
