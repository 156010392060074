@import "./variables";

$grid-columns: 12;
$grid-gap: 15px;

[class*="app-col-"] {
  display: flex;
  flex-direction: column;
  position: relative;
}

.grid-flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

@for $i from 1 through $grid-columns {
  $width: calc($i / $grid-columns * 100%);

  .app-col-#{$i} {
    width: calc(#{$width} - #{$grid-gap});
    margin: 10px 0;

    @media screen and (max-width: $tablet) {
      width: calc(50% - #{$grid-gap});
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .app-col-12 {
    width: 100%;
  }
}

.grid-flex-container .app-col-4 {
  &:nth-child(3n-1) {
    margin-left: 22.5px;
    margin-right: 22.5px;

    @media screen and (max-width: $tablet) {
      margin: 10px 0;
    }

    @media screen and (max-width: $mobile) {
      margin: 0;
    }
  }

  &:nth-child(2n-1) {
    @media screen and (max-width: $tablet) {
      margin: 10px 15px;
    }

    @media screen and (max-width: $mobile) {
      margin: 10px 0;
    }
  }
}