@import "./variables";

form {
  margin: 0 auto;
}

textarea {
  font-family: $font;
}

// Form groups

.form__group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form__group--center {
  align-items: center;
  margin: 20px 0;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.form__group--right {
  align-items: center;
  margin: 20px 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.form__group label {
  color: $lightGray3;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 0 5px;
}

// Links & buttons

.form__group a,
.form__group .link {
  color: $blue;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-decoration: none;
}

.form__group button a {
  line-height: 1;
}

// Checkboxes

.form__group--checkbox {
  margin: 20px 0;
}

table .form__group--checkbox {
  margin: 0;
}

.advanced-search__inner .form__group--checkbox-disabled,
.advanced-search__inner
  .form__group.form__group--checkbox-disabled:nth-last-of-type(2),
.form__group--checkbox-disabled {
  background: $gray3;
  color: $gray;
}

.form__group--checkbox label {
  padding: 0;
  color: $black;
  display: flex;
  align-items: center;
  max-width: 100%;
}

.advanced-search__inner .multi-select__option .form__group--checkbox {
  width: 100%;
  padding: 6px 8px;
  margin: 0;
}

.advanced-search__inner .multi-select__option .form__group--checkbox label {
  width: 100%;
}

.advanced-search__inner .multi-select__option .checkbox>span {
  max-width: 100%;
  text-overflow: initial;
  overflow: initial;
  white-space: initial;
  position: relative;
  display: flex;
}

.advanced-search__inner .multi-select__option .checkbox>span>span {
  width: calc(100% - 30px);
}

.advanced-search__inner .form__group input[type="text"].picky__filter__input {
  border: 1px solid $lightGray3;
  box-shadow: none;
  margin: 5px;
  height: 40px;
  width: calc(100% - 38px);
}

.advanced-search__inner .form__group input[type="text"].picky__filter__input:focus {
  border: 1px solid $lightGray3;
}

.radio,
.checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--pure-material-font,
      "Roboto",
      "Segoe UI",
      BlinkMacSystemFont,
      system-ui,
      -apple-system);
  font-size: 16px;
  line-height: 1.5;
}

/* Input */
.radio > input,
.checkbox > input {
  appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: $black2;
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.radio > span,
.checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;

  @media screen and (max-width: $mobile) {
    max-width: none;
  }
}

/* Box */
.checkbox>span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px;
  border-color: $blue;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

.radio > span {
  padding-left: 30px;
}

.radio > span::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 14px;
  z-index: 1;
  transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  margin-right: 20px;
}

.radio > span::after {
  content: "";
  position: absolute;
  top: 9px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: $blue;
  border-radius: 50%;
  z-index: 2;
  transform: scale(0, 0);
  transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.radio > input:checked + span::after {
  transform: scale(1, 1);
}

.form__group--error .checkbox > span::before {
  border-color: red;
}

td .checkbox>span::before,
th .checkbox>span::before {
  margin: 3px 1px 3px 1px;
}

/* Checkmark */
.checkbox>span::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.checkbox>input:checked,
.checkbox>input:indeterminate {
  background-color: $blue;
}

.radio > input:checked + span::before,
.radio > input:indeterminate + span::before {
  border-color: $blue;
}

.checkbox > input:checked + span::before,
.checkbox > input:indeterminate + span::before {
  border-color: $blue;
  background-color: $blue;
}

.checkbox>input:checked+span::after,
.checkbox>input:indeterminate+span::after {
  border-color: $white;
}

.checkbox>input:indeterminate+span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.checkbox:hover>input {
  opacity: 0.04;
}

.checkbox>input:focus {
  opacity: 0;
}

.checkbox:hover>input:focus {
  opacity: 0;
}

/* Active */
.checkbox>input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.checkbox>input:active+span::before {
  border-color: $blue;
}

.checkbox>input:checked:active+span::before {
  border-color: transparent;
  background-color: $black2;
}

/* Disabled */
.checkbox>input:disabled {
  opacity: 0;
}

.checkbox>input:disabled+span {
  color: $gray;
  cursor: initial;
}

.checkbox>input:disabled+span::before {
  border-color: currentColor;
}

.checkbox>input:checked:disabled+span::before,
.checkbox>input:indeterminate:disabled+span::before {
  border-color: transparent;
  background-color: currentColor;
}

// Selects & Multiselects

.form__group .select__control {
  cursor: pointer;
  border: 1px solid $lightGray3;
}

.multi-select {
  box-shadow: 0 9px 9px $darkBlue, 0 0 1px $darkBlue;
}

.multi-select__close-dropdown {
  position: absolute;
  right: 0px;
  top: 10px;

  @media screen and (max-width: $mobile) {
    right: 10px;
  }
}

.form__group .select__option,
.form__group .multiselect__option {
  cursor: pointer;
  color: $black;
  text-align: left;
}

.multi-select .picky__dropdown {
  top: 65px;
  border-radius: $borderRadius;
  padding: 2px;
  z-index: 1;
  overflow-y: hidden !important;
  box-shadow: 0 9px 9px $darkBlue, 0 0 1px $darkBlue;
  width: 528px;
}

.multi-select__wrapper {
  display: flex;
}

.multi-select__list,
.multi-select__selected {
  width: 50%;
}

.multi-select__selected {
  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.multi-select__list {
  @media screen and (max-width: $mobile) {
    width: 100%;
  }
}

.multi-select__selected-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.multi-select__selected-list {
  overflow: auto;
  height: 190px;
}

#picky-rating-list {
  height: 185px;
  width: 256px !important;
  overflow: hidden;
}

.form__group .select__option {
  cursor: pointer !important;
}

.form__group .select__option--is-selected {
  background: $blue !important;
  color: $white !important;
}

.form__group .select__option:hover:not(.select__option--is-selected),
.form__group .multi-select__option:hover:not(.multi-select__option--is-selected) {
  background: $lightGray;
}

.form__group .select__control:hover,
.form__group .select__control--is-focused,
.form__group .multi-select__control:hover,
.form__group .multi-select__control--is-focused {
  border: 1px solid $blue;
  box-shadow: none;
}

.form__group .select__control--is-disabled {
  background: $gray3;
}

.form__group--select button+div,
.select__menu {
  animation-name: selectFade;
  animation-duration: 0.3s;
  z-index: 2 !important;
}

@keyframes selectFade {
  from {
    transform: translateY(-10%) scale(0.5);
    opacity: 0;
  }

  to {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

.select-dropdown {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid $gray;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  right: 16px;
  top: calc(50% - 4px);
}

.multi-select .picky__input {
  border: none;
}

.multi-select .picky__placeholder {
  margin: 0 2px !important;
  top: 1px;
  position: relative;
  font-size: 13px;
}

.multi-select .picky__input::after {
  top: calc(50% - 14px);
  content: " ";
  @include background("icons/dropdown-indicator.png");
  background-size: 28px;
  height: 28px;
  width: 28px;
  display: block;
  border: none !important;
}

// Inputs

.form__group input[type="text"],
.form__group input[type="number"],
.form__group input[type="email"],
.form__group input[type="password"] {
  border-radius: $borderRadius;
  border: 1px solid $lightGray3;
  height: 37px;
  line-height: 37px;
  padding: 0 15px;
  width: 100%;
  transition: border ease-in 0.1s;
}

.form__group input[type="text"]:focus,
.form__group input[type="number"]:focus,
.form__group input[type="email"]:focus,
.form__group input[type="password"]:focus,
.form__group textarea:focus {
  border: 1px solid $blue;
}

.form__group input[type="text"]:disabled,
.form__group input[type="number"]:disabled,
.form__group input[type="email"]:disabled,
.form__group input[type="password"]:disabled,
.form__group textarea:disabled {
  background: $gray3;
}

.form__group textarea {
  border-radius: $borderRadius;
  border: 1px solid $lightGray3;
  height: 200px;
  line-height: 1.5;
  padding: 10px 15px;
  width: 100%;
  transition: border ease-in 0.1s;
}

.form__group input[type="number"]::-webkit-inner-spin-button,
.form__group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

// Errors

.form__group--error input[type="text"],
.form__group--error input[type="number"],
.form__group--error input[type="email"],
.form__group--error input[type="password"],
.form__group--error input[type="text"]:focus,
.form__group--error input[type="number"]:focus,
.form__group--error input[type="email"]:focus,
.form__group--error input[type="password"]:focus,
.form__group--error textarea,
.form__group--error textarea:focus,
.form__group--error .select__control,
.form__group--error .select__control:focus {
  border: 1px solid red;
}

.form__error {
  font-size: 12px;
  color: red;
  margin-top: 5px;
}

.required-star {
  color: red;
}

.checkbox .required-star {
  margin-right: 10px;
}

// Loading indicator

.form__group .loading__wrapper {
  margin: 0 0 0 15px;
  width: auto;
}

.form__group .loading__indicator {
  width: 20px;
  height: 20px;
  border: 2px solid $lightGray2;
  border-top: 2px solid $blue;
}

.ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}

.login-text {
  max-width: 500px;
  text-align: center;
  font-size: 15px;
  margin-left: auto;
  margin-right: auto;
}

.register-text {
  font-size: 12px;
  text-align: left;
}

.form__group .ReactPasswordStrength {
  width: 100%;
  border: none;
}

.form__group .ReactPasswordStrength-strength-desc {
  padding: 8px 12px;
  width: 120px;
  height: 37px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  font-style: inherit;
}

.form__group .ReactPasswordStrength-strength-bar {
  top: -3px;
  right: 0;
  height: 4px;
}
