@import "./variables";

.picky {
  width: 100%;
  position: relative;
}
.picky * {
  box-sizing: border-box;
}
.picky__input {
  width: 100%;
  background-color: white;
  border: 0;
  border-width: 1px;
  border-color: #eee;
  border-style: solid;
  border-radius: 2px;
  padding: 5px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  height: 38px;
}

.picky__input--disabled,
.picky__input--disabled + .picky__dropdown .option {
  color: rgb(95, 94, 94);
}
.picky__input::after {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  right: 16px;
  top: 16px;
}
.picky__dropdown {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  top: 39px;
}
.picky__dropdown .option,
.picky__dropdown li {
  list-style: none;
  background-color: white;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  padding: 0.5em;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  text-align: left;
}
.picky__dropdown .option:hover,
.picky__dropdown li:hover {
  background-color: #ecf0f1;
}
.picky__dropdown .option.selected,
.picky__dropdown li.selected {
  background-color: #ecf0f1;
}
.picky__dropdown .option:focus,
.picky__dropdown li:focus {
  outline: -webkit-focus-ring-color auto 5px !important;
}
.picky__dropdown .option input[type="checkbox"],
.picky__dropdown .option input[type="radio"],
.picky__dropdown li input[type="checkbox"],
.picky__dropdown li input[type="radio"] {
  margin-right: 3px;
}
.picky__filter {
  display: flex;
  width: 100%;
  padding: 3px;
}
.picky__filter__input {
  width: 100%;
  padding: 3px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: $borderRadius;
  position: relative;
  height: 30px;
}
