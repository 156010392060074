// Colors

$black: #000;
$black2: rgba(0, 0, 0, 0.3);
$black3: rgba(0, 0, 0, 0.85);
$gray: #444;
$gray2: #7a7673;
$gray3: #ddd;
$gray4: #808080;
$gray5: #e0e0df;
$lightGray: #f7f8f8;
$lightGray2: #f0efef;
$lightGray3: #c1c1c1;
$lightGray4: #f6f6f6;
$lightGray5: #8492a9;
$white: #fff;
$white2: rgba(255, 255, 255, 0.5);
$white3: rgba(255, 255, 255, 0.7);
$blue: #004d8f;
$blueHover: #105fa3;
$blueTransparent: rgba(0, 76, 143, 0.7);
$lightBlue: #f4fbff;
$lightBlue2: #a6c3d6;
$lightBlue3: #f3f7fa;
$darkBlue: rgba(10, 31, 68, 0.12);
$darkBlue2: rgba(10, 31, 68, 1);
$yellow: #ffd966;
$green: #6e904f;
$red: #9c1f10;
$orange: #ffa500;
$darkOrange: #ff8c00;

// Images

$imagesUrl: "/images";

// Fonts

$font: "Roboto", sans-serif;
$codeFont: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

// Breakpoints

$mobileXs: 360px;
$mobileIframe: 600px;
$mobile: 768px;
$tabletXs: 992px;
$tablet: 1023px;
$midDesktop: 1200px;
$desktop: 1400px;

// UI

$ratingCircleSize: 140px;
$boxShadow: 0 9px 9px $darkBlue, 0 0 3px $darkBlue;
$borderRadius: 5px;

@mixin background(
  $image,
  $position: 0 0,
  $repeat: no-repeat,
  $color: transparent
) {
  background-image: url("#{$imagesUrl}/#{$image}");
  background-position: $position;
  background-repeat: $repeat;
  background-color: $color;
}
