@import "./variables";

.app-container .app__tooltip {
  background-color: $black3;
  font-weight: normal;
  font-family: $font;
  letter-spacing: normal;
  text-transform: none;
}

.app-container .app__tooltip::after {
  border-right-color: $black3;
}

.app-container .app__tooltip--bottom::after,
.app-container .app__tooltip--top::after {
  border-bottom-color: $black3;
  border-right-color: transparent;
}

.selected-fund__header .app__tooltip,
.selected-fund .app__tooltip,
.fund__header .app__tooltip,
.fund__boxes .app__tooltip,
.fund__more .app__tooltip,
.legend .app__tooltip,
.scope-awards-page .app__tooltip,
.fund__number .app__tooltip,
.subscription-rating__tooltip .app__tooltip {
  max-width: 250px;
  text-align: left;
}

#crash-drawdown-tooltip,
.premium__content .app__tooltip {
  max-width: 350px;
}

.premium__content .app__tooltip {
  @media screen and (max-width: $mobile) {
    max-width: calc(100% - 20px);
    width: 100%;
    left: 10px!important;
  }
}

.asset-manager-details-top__description .app__tooltip,
.asset-manager__summary .app__tooltip {
  max-width: 450px;
}