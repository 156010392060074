@import "./styles/variables";

body {
  margin: 0;
  padding: 0;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}

#root {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

code {
  font-family: $codeFont;
}

button:focus,
input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: $lightGray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $gray2;
}

::-ms-clear {
  display: none;
}

.app-bg {
  background: transparent;
  min-height: 1250px;
  position: absolute;
  width: 100%;
  top: 70px;

  @media screen and (max-width: $mobile) {
    background: transparent;
    min-height: 700px;
  }
}

.app-bg::before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  @include background("bg.jpg");
  background-size: cover;

  @media screen and (max-width: $mobile) {
    @include background("bg-mobile.jpg");
    background-size: cover;
    min-height: 800px;
  }
}

.app-wrapper {
  min-height: calc(100vh - 180px);
  margin-top: 80px;

  @media screen and (max-width: $mobile) {
    margin-top: 0;
  }
}

.app-container {
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  position: relative;

  @media screen and (max-width: $midDesktop) {
    padding: 0 15px;
  }

  @media screen and (max-width: $mobile) {
    padding: 0 10px;
  }
}

.app-container--small {
  max-width: 800px;
  position: relative;
}

.sidebar-menu {
  background: $white;
  border-radius: $borderRadius;
  padding: 20px 0;
  position: absolute;
  width: 270px;
  box-shadow: $boxShadow;

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.sidebar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu ul li {
  cursor: pointer;
  transition: background ease-in 0.1s, color ease-in 0.1s;
}

.sidebar-menu ul li a,
.sidebar-menu ul li button {
  display: flex;
  width: 100%;
  padding: 12px 20px;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
  text-align: left;
}

.sidebar-menu ul li:hover,
.sidebar-menu ul li button:hover,
.sidebar-menu ul li button.active {
  background: $lightGray;
  color: $blue;
}

.link {
  color: $blue;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.link--right {
  text-align: right;
  margin-bottom: 20px;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  transform: none;
}

.highcharts-series,
.highcharts-polygon-series,
.highcharts-series-inactive {
  opacity: 1;
}

.rating-date__container {
  padding: 10px 0;
}

.rating-date__container .rating-date {
  margin: 0 auto;
}

.rating-date {
  margin: 10px auto;
  color: $gray4;
  display: block;
  text-align: center;
}

.funds-list-hint {
  margin: 10px auto;
  color: $gray4;
  display: block;
  text-align: center;
}

.rating-date--advanced-search {
  margin-bottom: 30px;
}

.unsubscribe-container {
  text-align: center;
}

.text--blue {
  color: $blue;
}